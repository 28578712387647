import React from 'react';
import { Home } from './views/Home';
import { About } from './views/About';
import { Contact } from './views/Contact';
import { Where } from './views/Where';
import { Event } from './views/Events';
import { Subscribe } from './views/Subscribe';
import { Beers } from './views/Beers';
import { Gins } from './views/Gins';

import { Privacy } from './views/Privacy';
import { NavBar } from './components/NavBar';
import { Footer } from './components/Footer';
import { Route, Switch, Redirect } from 'react-router-dom';

export const Routes = () => {
  return (
    <div>
      <NavBar />
      <Switch>
        <Route exact path="/Home" component={Home} />
        <Route exact path="/">
          <Redirect to="/Home" />
        </Route>
        <Route exact path="/Where" component={Where} />
        <Route exact path="/Events" component={Event} />
        <Route exact path="/Subscribe" component={Subscribe} />
        <Route exact path="/Beers" component={Beers} />
        <Route exact path="/Gins" component={Gins} />
        <Route exact path="/About" component={About} />
        <Route exact path="/Contact" component={Contact} />
        <Route exact path="/Privacy" component={Privacy} />
      </Switch>
      <Footer />
    </div>
  );
};
