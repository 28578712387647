import React, {Component, useState} from 'react';
import { Badge, Modal, Form, InputGroup, FormControl, Alert, Container, Button,Card, Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleMap, LoadScript } from '@react-google-maps/api';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-217147309-1');
ReactGA.pageview(window.location.pathname + window.location.search);


library.add(fas, fab);

export default class Where extends Component {

  constructor(props) {
    super(props)
    this.state = {
      outlets : [],
      viewOptions: [],
      viewOption: "All Outlets",
      readOnlyViewOptions: [],
      show: false,
      setShow: false,
      modalData: {},
      gpsAvailable: false,
      gpsLat: "",
      gpsLon: "",
    }
  }

  async componentDidMount(){
    // Runs after the first render() lifecycle
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type');

    await fetch('https://www.dhubrew.com:8084/dhubrew/getresellers')
    .then((response) => response.json())
    .then(response => {
        this.setState({ outlets: response });
        let options = ["All Outlet"];
        for (let x in response) {
          let resp = response[x];
          let type = resp["type"];
          options.push( type);
        }
        options = options.filter((x, i, a) => a.indexOf(x) === i);
        if (type === "Pub"){
        var newArray = response.filter( function(el) { return el.type === type});
        this.setState({
          viewOption: type,
          outlets: newArray})
        }
        this.setState({
          viewOptions: options,
          readOnlyOutlets: response,

        })
    });
    if ("geolocation" in navigator) {

        this.setState({
          gpsAvailable: true
      });
    
      console.log("GPS Available");
    } else {
      this.setState({gpsAvailable: false});
      console.log("GPS Not Available");
    }
    document.body.style.background = '#000000';
  }

  handleClose = () => {
    this.setState({
      show: false
    })
  }
  
  handleShow = (e, item) => {
    console.log("show called");
    this.setState({
      modalData: item,
      show: true
    })
  }

handleViewOption = (event) => {
  const workOutlets = this.state.readOnlyOutlets;
  const typeSelect = event.target.value;
  if (event.target.value === "All Outlet"){
    this.setState({
      outlets: workOutlets,
      viewOption: typeSelect})
  } else {
    var newArray = workOutlets.filter( function(el) { return el.type === typeSelect});
    this.setState({
        viewOption: typeSelect,
        outlets: newArray})
  }

}

  render(){


    return (
      <Container className="mx-auto" style={{ backgroundColor: '#000000'}}>
        <div style={{display: 'flex', alignContent: 'center', justifyItems: 'center', marginBottom: '50px' , marginTop: '75px'}}></div>
        <div>
         <Alert variant='warning'>Places where you can find DhuBrew Beers  
         <Form
         
         >
           <Form.Select 
           value={this.state.viewOption}
           id="viewoption"
           onChange={this.handleViewOption}
           >
             {this.state.viewOptions.map((option,id) => (<option id={{id}} value={option}>{option.replace("PubRestaurant","Pub/Restaurant").replace("OffLicence","Off-Licence")}{'s'}</option>))}

            </Form.Select>
          </Form>
         <hr />
         We are not yet selling our Beer direct from the website, but you can find out where it’s
          available in the WHERE section of the Website. If you <Alert.Link href='/Subscribe'>subscribe</Alert.Link> we’ll  let you know when you can also buy it online.
         <hr />
        {this.state.gpsAvailable && <Button variant="warning">Near me</Button>}
        </Alert>

        </div>
        <div style={{display: 'flex', alignContent: 'center', justifyItems: 'center', marginBottom: '150px' , marginTop: '50px'}}>
        <Row xs={1} md={3} className="g-4">
        {this.state.outlets.map((item, idx) => (
          <Col key={idx}  style={{marginTop: "10px", alignContent: 'center', justifyItems: 'center'}}>
            <Card id={idx} bg="warning" border="dark" style={{  height: '550px'}}> 
            <Card.Header>
                  {item.type.replace("PubRestaurant","Pub/Restaurant").replace("OffLicence","Off-Licence")}{' '}
                  {item.withstock == "1" && <Badge pill bg="success">Regular stockist</Badge>}
                  {item.withstock == "0"  && <Badge pill bg="danger">On rotation!</Badge>}

                </Card.Header>
              <Card.Body>
                <Card.Title>{item.name}</Card.Title>

                  <Card.Text>
                    {item.about.replaceAll("&#039;","'").replaceAll("&quot;",'"').substring(0,400)}
                  </Card.Text>
                  <Card.Text>
                  <FontAwesomeIcon size='1x' color='#000000' icon={['fas', "building"]} /> {' '}
                    {item.address_l1.replaceAll("&#039;","'")},{' '}
                    {item.city}, {' '} {item.postcode}
                  </Card.Text>
                  <Card.Text>
                  <FontAwesomeIcon size='1x' color='#000000' icon={['fas', "phone"]} /> {item.phone}
                  </Card.Text>
                  <Card.Text>
                  <FontAwesomeIcon size='1x' color='#000000' icon={['fas', "paper-plane"]} /> {item.email}
                  </Card.Text>

                  </Card.Body> 
                  <Card.Footer>
                  <Card.Link href={item.link}>
                  <Button variant="warning">Visit</Button>
                  </Card.Link>
                  <Card.Link>
                 <Button variant="warning" onClick={((e) => { 
			ReactGA.event({
			  category: 'User',
  			  action: item.name
			});

			this.handleShow(e, item);
		}
		)}>
		Map
		</Button>
                  </Card.Link>
                  <Card.Link>
                    <Button variant="warning">
                      <FontAwesomeIcon size='1x' color='#000000' icon={['fab', 'twitter']} />
                    </Button>
                  </Card.Link>
                  <Card.Link >
                  <Button variant="warning" >         
			            <FontAwesomeIcon size='1x' color='#000000' icon={['fab', "facebook"]} />
                  </Button>
                  </Card.Link>
                  </Card.Footer>
            </Card>
          </Col>
        ))}
        </Row>
          </div>


        <Modal
        show={this.state.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Dhu Brew Treasure Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
          <LoadScript googleMapsApiKey="AIzaSyBtWwBGUQhRQRGUl3DEIVOKv_aFMB7V1qM" >
          <GoogleMap
            mapContainerStyle ={{width: '400px', height: '400px'}}
            center= {{lat: Number(this.state.modalData.lat), lng: Number(this.state.modalData.lon)}}
            zoom={18}
            >

            </GoogleMap>
          </LoadScript>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={this.handleClose}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>




      </Container>
    )}
}

