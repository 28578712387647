import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import dhubrewblack from './dhubrewblack.png';
import DBbyStow_black from './DBbyStow_black.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.css';



const NavBar = () => {
  return (
<Container >
      <Navbar expand='lg' className="DhuBrewNavBar" fixed="top">
	    <Container>
    		<Navbar.Brand href="/Home">
      			<img
		        	src = {DBbyStow_black}
		        	width="150px"
		        	className="d-inline-block align-center"
		        	alt="Dhu Brew Logo"
      			/>
		</Navbar.Brand>
	    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    		<Navbar.Collapse id="basic-navbar-nav">
	          <Nav>
			  <Nav.Link href="/Home">Home</Nav.Link>
      		          <Nav.Link href="/Beers">Beers</Nav.Link>
						<Nav.Link href="/Gins">Gins</Nav.Link>
                          <Nav.Link href="/Where">Where</Nav.Link>
                          <Nav.Link href="/Events">Dhu It!</Nav.Link>
                          <Nav.Link href="/Subscribe">Subscribe</Nav.Link>
                  </Nav>
		</Navbar.Collapse>
            </Container>
      </Navbar>
</Container>
  );
};

export default NavBar;
