import React, {Component, useState} from 'react';
import { Badge, Container, Button,Card, Row, Col, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jf from './jez_2.jpg';
import mm from './Michael-Middlemiss.jpg';
import tt from './Tom square.jpeg';
import cs from './suit.jpg';
import as from './alan-sanderson.jpg';
import blank from './blank-profile-picture.jpg';
import sw from './Stuart square.jpg';
import stowbrewery from './stow_brewery_1.png';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-217147309-1');
ReactGA.pageview(window.location.pathname + window.location.search);


library.add(fas, fab);

export default class About extends Component {

  constructor(props) {
    super(props)
    this.state = {
      beers : [],

    }
  }

  async componentDidMount(){
    // Runs after the first render() lifecycle
    document.body.style.background = '#000000';
  }


  render(){


    return (
      <Container className="mx-auto" style={{ backgroundColor: '#000000',marginBottom: '150px', marginTop: '100px'}}>
        <Row className="align-items-center">
          <Col xs={0} md={4}></Col>
          <Col xs={18} md={4}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Image src={stowbrewery} style={{width: '20rem'}} />
            </div>
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>

        <Row  className="align-items-center">
          <Col xs={0} md={3}></Col>
          <Col xs={18} md={6}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <p style={{color: '#fdc300'}}><i>The founding Dhu Brew Investors ...</i> </p>
            <p></p>
            </div>
          </Col>
          <Col xs={0} md={3}></Col>
        </Row>

        <Row  className="align-items-center">
          <Col xs={0} md={3}></Col>
          <Col xs={18} md={6}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <Image style={{width: '400px'}} src='https://dhubrew.com/images/dhubrewwordart.png' />
            </div>
          </Col>
          <Col xs={0} md={3}></Col>
        </Row>

        <Row  className="align-items-center">
          <Col xs={0} md={3}></Col>
          <Col xs={18} md={6}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <p style={{color: '#fdc300'}}><i>and the 'current' hands on team ...</i> </p>
            <p></p>
            </div>
          </Col>
          <Col xs={0} md={3}></Col>
        </Row>
<Row xs={1} md={3} className="g-4 mx-auto">

    <Col>
      <Card bg='warning' bordr='warning' >
        <Card.Img variant="top" src={jf} className='m-auto' style={{width: '8rem'}}/>
        <Card.Body>
          <Card.Title>Jez Dhuvara <Badge pill bg='danger'>Dhu Chair</Badge></Card.Title>
          <Card.Text>
            The wizened vision behind Dhu Brew's drive to make good beer in the Borders.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col>
      <Card bg='warning' variant='warning' >
      <Card.Img variant="top" src={mm} className='m-auto' style={{width: '8rem'}}/>
        <Card.Body>
          <Card.Title>Michael Middlemass <Badge pill bg='danger'>CFO</Badge></Card.Title>
          <Card.Text>
            The numbers man, chief taster and the voice of reason at the Dhu Brew table.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col>
      <Card bg='warning' variant='warning' >
      <Card.Img variant="top" src={tt} className='m-auto' style={{width: '8rem'}}/>
        <Card.Body>
          <Card.Title>Tom Thomson <Badge pill bg='danger'>New Boy</Badge></Card.Title>
          <Card.Text>
            Dhu Brew Crew Sales Guru, try saying that with a pickle in your mouth.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

<Col>
      <Card bg='warning' variant='warning' >
      <Card.Img variant="top" src={cs} className='m-auto' style={{width: '8rem'}}/>
        <Card.Body>
          <Card.Title>Mr Blog</Card.Title>
          <Card.Text>
            Head of colouring in on the Dhu Brew Crew and great on social media.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col>
      <Card bg='warning' variant='warning' >
      <Card.Img variant="top" src={sw} className='m-auto' style={{width: '8rem'}}/>
        <Card.Body>
          <Card.Title>Stuart Watkins</Card.Title>
          <Card.Text>
            The Dhu Brew lift and shift expert. When things need taken somewhere he is the guy.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col>
      <Card bg='warning' variant='warning' >
      <Card.Img variant="top" src={as} className='m-auto' style={{width: '8rem'}}/>
        <Card.Body>
          <Card.Title>Alan Sanderson</Card.Title>
          <Card.Text>
            Bringing the Dhu Brew Crew the technology to thrive at a bargain price.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
</Row>
      </Container>
    )}
}
