import React, {Component, useState} from 'react';
import { Badge, Container, Button,Card, Row, Col, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import redaleshield  from './redaleshield.png';
import ipashield  from './ipashield.png';
import present from './presents_1.png';
import stowbrewery from './stow_brewery_1.png';
import pilsshield from './Pils Shield.png';
import stowtshield from './Stowt Shield.png';
import sportshield from './SportShield.png';
import ginlable from './Gin Label.png';
library.add(fas, fab);

export default class Gins extends Component {

  constructor(props) {
    super(props)
    this.state = {
      beers : [],

    }
  }

  async componentDidMount(){
    // Runs after the first render() lifecycle
    document.body.style.background = '#000000';
  }


  render(){


    return (
      <Container className="mx-auto" style={{ backgroundColor: '#000000',marginBottom: '150px', marginTop: '75px'}}>
        <Row className="align-items-center">
          <Col xs={0} md={4}></Col>
          <Col xs={18} md={4}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Image src={stowbrewery} style={{width: '20rem'}} />
            </div>
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>

        <Row  className="align-items-center">
          <Col xs={0} md={4}></Col>
          <Col xs={18} md={4}>
          <div style={{display: 'flex', justifyContent: 'center',marginBottom: '25px'}}>
            <Image src={present} style={{width: '14rem'}} />
            </div>
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>


        <Row  className="align-items-center">
          <Col xs={0} md={4}></Col>
          <Col xs={18} md={4}>
          <div style={{display: 'flex', justifyContent: 'center',marginBottom: '25px'}}>
            <Image src={ginlable} style={{width: '48rem'}} />
            </div>
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>

      </Container>
    )}
}
