// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Cowsbw.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".common-view {\n        max-width: '100%';\n\twidth: '100%';\n\tmargin: 0;\n\tdisplay: flex;\n\tbackground-color: black;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.common-font {\n\tcolor: yellow;\n}\n\n.Hbackground-image {\n    background-image: linear-gradient(\n        rgba(223, 214, 197, 0.7),\n        rgba(223, 214, 197, 0.6)\n    ),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    min-width: 100%;\n    min-height: 100%;\n    background-repeat: no-repeat;\n    background-color: black;\n    margin-top: 70px;\n    position: absolute;\n    width: 100%;\n    background-position: center; \n    align-items: center;\n    justify-content: center;\n    display: flex;\n    margin: 0;\n\n}\n", "",{"version":3,"sources":["webpack://src/views/Home/Home.css"],"names":[],"mappings":"AAAA;QACQ,iBAAiB;CACxB,aAAa;CACb,SAAS;CACT,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,aAAa;AACd;;AAEA;IACI;;;6CAGmB;IACnB,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,2BAA2B;IAC3B,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,SAAS;;AAEb","sourcesContent":[".common-view {\n        max-width: '100%';\n\twidth: '100%';\n\tmargin: 0;\n\tdisplay: flex;\n\tbackground-color: black;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.common-font {\n\tcolor: yellow;\n}\n\n.Hbackground-image {\n    background-image: linear-gradient(\n        rgba(223, 214, 197, 0.7),\n        rgba(223, 214, 197, 0.6)\n    ),url(./Cowsbw.jpg);\n    background-size: cover;\n    min-width: 100%;\n    min-height: 100%;\n    background-repeat: no-repeat;\n    background-color: black;\n    margin-top: 70px;\n    position: absolute;\n    width: 100%;\n    background-position: center; \n    align-items: center;\n    justify-content: center;\n    display: flex;\n    margin: 0;\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
