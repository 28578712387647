import React, {Component, useState} from 'react';
import { Alert, Row, Col, Form, InputGroup, FormControl, Container, ButtonGroup, Button, Modal,Image } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Privacy.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DBbyStow_black from './DBbyStow_black.png';


library.add(fas, fab);

export default class Privacy extends Component {
constructor(props) {
	super(props)
	this.state = {
		givenemail: "",
		show: false,
		setShow: false
	}
}


render(){



  return (
<Container className="Hbackground-image">
    <div>
<Row className="align-items-center" style={{marginTop: '75px', marginBottom: '25px'}}>
    	<Col xs={0} md={4}></Col>
    	<Col xs={18} md={4}>
		<div style={{display: 'flex', justifyContent: 'center'}}>
			  <img src={DBbyStow_black} style={{width: '300px' }}/>
		</div>
    	</Col>
    	<Col xs={0} md={4}></Col>
  </Row>

  <Row className="align-items-center" style={{marginBottom: '10px'}}>
    <Col xs={0} md={3}>

    </Col>
    <Col xs={18} md={6}>
	<p className='text-dark' ><strong>Privacy Policy</strong></p>
    <p className='text-dark' >
        Protecting your privacy is very important to us at Stow Brewery Limited. 
        This Privacy Policy explains how we collect, process and share information when you use our website dhubrew.com 
        and when you give us your details for us to contact you.
    </p>
    </Col>
    <Col xs={0} md={3}>
      
    </Col>
  </Row>

  <Row className="align-items-center" style={{marginBottom: '10px'}}>
    <Col xs={0} md={3}></Col>
      <Col className="align-items-center"> 
        <Button variant="warning" href='./docs/dhubrew_privacy_policy_feb_2022.pdf'>
                <FontAwesomeIcon size='2x' color='#111111' icon={['fas', 'file-pdf']} />
                {'   '}Dhu Brew privacy policy 
          </Button> 
      </Col>
    <Col xs={0} md={3}></Col>
  </Row>
  </div>
</Container>
  )}}
