import React, {Component, useState} from 'react';
import { Spinner, Modal, Form, InputGroup, Container, Button,Card, Row, Col, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import stowbrewery from './stow_brewery_1.png';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-217147309-1');
ReactGA.pageview(window.location.pathname + window.location.search);


library.add(fas, fab);

export default class Contact extends Component {

  constructor(props) {
    super(props)
    this.state = {
       email: "",
       to: "",
       note: "",
       allowSubmit: false,
       emailOk: false,
       toOk: false,
       noteOk: false,
       waitingModalShow: false,
       errorStatus: ""
    }
  }

  async componentDidMount(){
    // Runs after the first render() lifecycle
    document.body.style.background = '#000000';
  }

handleWaitShow = () => {
	this.setState({
		waitingModalShow: false
	});

}

handleSubmit = () => {
	const url = "https://www.dhubrew.com:8084/dhubrew/contact";
	const datapacket = {
		email: this.state.email, 
		note: this.state.note,
    to: this.state.to,
	};
	this.setState({
		waitingModalShow: true
	});
	fetch( url, {
		method: 'POST',
	
		body: JSON.stringify(datapacket),
		headers: {
			'Content-Type': 'application/json', 
			'Accept': 'application/json', 
            "Access-Control-Allow-Headers" : "Content-Type, Accept",
            "Access-Control-Allow-Origin": "https://www.dhubrew.com",
            "Access-Control-Allow-Methods": "GET, POST"
		}
	})
	.then((res) => res.json())
	.then((data)=> {
		this.setState({errorStatus: data.status });
		if (data.status === "okay"){
			this.setState({
				waitingModalShow: false,
			})
      this.props.history.push({
        pathname: "/home",
        data: this.state.email
    })
		}
	})
	.catch(err => {const mute = err });
};


  render(){
    return (
      <Container className="mx-auto" style={{ backgroundColor: '#000000',marginBottom: '200px', marginTop: '100px'}}>
        <Row className="align-items-center">
          <Col xs={0} md={4}></Col>
          <Col xs={18} md={4}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Image src={stowbrewery} style={{width: '20rem'}} />
            </div>
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>

        <Row  className="align-items-center" style={{marginTop: '25px'}}>
          <Col xs={0} md={3}></Col>
          <Col xs={18} md={6}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
              <Card id="contactform" bg="warning" border="dark" style={{ }}>
                <Card.Header as='h5'>
                <FontAwesomeIcon icon={['fas', 'envelope']} /> {'  '} Get in touch ...
                </Card.Header>
                <Form>

                <Card.Body>
                <Form.Group className="mb-3">
                    <Form.Label>
                            You can drop us a note here at Dhu Brew with any question or to get 
                            in touch if you're trade and want to stock our fine beers!
                            We aim to reply to all questions within 48 hours. If you have not received 
                            a reply within this time, please check your spam folder.  
                        
                    </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                <InputGroup className="mb-3"> 
                         <Form.Control 
                          type="email" 
                          placeholder="your email ..." 
                          id="email" name="email" 
                          onChange={e =>  
                            { 
                              this.setState({ email: e.target.value });
                              if(e.target.value.includes("@")){
                                this.setState({emailOk: true})
                              } else { this.setState({emailOk: false})}
                            }}

                            />
                          <InputGroup.Text>
                          {this.state.emailOk && <FontAwesomeIcon color='green' icon={['fas', 'check']} />}
                          {!this.state.emailOk && <FontAwesomeIcon color='red' icon={['fas', 'times']} />}
                          
                          </InputGroup.Text>

                </InputGroup>
                            <Form.Text size='sm' className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formType">
 
                    <Form.Label>Who do you want to see your query? </Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Select 
                          id="to" 
                          name="to" 
                          onChange={e =>  
                          {
                            this.setState({ to: e.target.value });
                            if(e.target.value !== "none"){
                              this.setState({toOk: true})
                            } else { this.setState({toOk: false})}
                          }
                        }
                          >
                            <option value="none">Select an option ...</option>
                            <option value='sales'>Trade sales</option>
                            <option value='subadmin'>Subscription admin</option>
                            <option value='webadmin'>Web admin</option>
                            <option value="generalenquiry">General enquiry</option>
                            </Form.Select>
                            <InputGroup.Text>
                          {this.state.toOk && <FontAwesomeIcon color='green' icon={['fas', 'check']} />}
                          {!this.state.toOk && <FontAwesomeIcon color='red' icon={['fas', 'times']} />}
                          
                          </InputGroup.Text>
                </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formQuery">
                    <Form.Label>A few words please on how we can help</Form.Label>
                    <InputGroup>
                        <Form.Control 
                          as="textarea" 
                          rows={5} 
                          placeholder="type away ..." 
                          id="note" 
                          name="note"
                          value={this.state.note}
                          onChange={e =>  
                          {
                          this.setState({ note: e.target.value.replace(/[^\w\s]/gi, '') })
                          if(e.target.value !== ""){
                            this.setState({noteOk: true})
                          } else { this.setState({noteOk: false})}
                          }
                          }
                           />
                          <InputGroup.Text>
                          {this.state.noteOk && <FontAwesomeIcon color='green' icon={['fas', 'check']} />}
                          {!this.state.noteOk && <FontAwesomeIcon color='red' icon={['fas', 'times']} />}
                          
                          </InputGroup.Text>
                </InputGroup>
                </Form.Group>

                </Card.Body>

                <Card.Footer className="text-center">
                     <Button
                        
                        variant="warning"
                        onClick={this.handleSubmit}
                        disabled={!(this.state.toOk && this.state.noteOk && this.state.emailOk)}
                        >

                      Submit
                    </Button>
                </Card.Footer>  
            </Form>

            </Card>
              </div> 
          </Col>
          <Col xs={0} md={3}></Col>
        </Row>



        <Modal
	 size="sm"
	 centered
	 id='waitingmodal'
	 show={this.state.waitingModalShow}
	 backdrop="static"
	 keyboard={false}
	 >
    <Modal.Body style={{alignContents: 'center'}}>
		 <p>Sending your note to the Dhu Brew Crew ...</p>
		 <br/>
		<Row >
			<Col></Col>
			<Col className="align-items-center">
				<Spinner
					animation="border" 
					role="status"
					size="lg"
					variant='warning'
					>
  					<span className="visually-hidden">Loading...</span>
				</Spinner>
		</Col>
		<Col></Col>
		</Row>

    {this.state.errorStatus === "okay" &&
			<div>
					<Row>
						<Col>
							<p><strong>Job done ... </strong></p>
              <p></p>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button variant='warning' onClick={this.handleWaitShow}>Close</Button>
						</Col>
					</Row>
			</div>}



			{this.state.errorStatus === "fail" &&
			<div>
					<Row>
						<Col>
							<p><strong>Something went wrong</strong></p>
              <p>{this.state.errorStatus}</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button variant='warning' onClick={this.handleWaitShow}>Cancel</Button>
						</Col>
					</Row>
			</div>}
	</Modal.Body>
	</Modal>
      </Container>
    )}
}
