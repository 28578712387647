import React, {Component, useState, useEffect, useRef} from 'react';
import { Card, Spinner, Alert, Row, Col, Form, InputGroup, FormControl, Container, Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Subscribe.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DBbyStow_black from './DBbyStow_black.png';
import ReactGA from 'react-ga';



ReactGA.initialize('UA-217147309-1');
ReactGA.pageview(window.location.pathname + window.location.search);
library.add(fas, fab);
export default class Subscribe extends Component {
constructor(props) {
	super(props)
	this.state = {
		initalGetUpdates: true,
		givenemail: "",
		show: false,
		setShow: false,
		submitOkay: false,
		acceptConditions: false,
		waitingModalShow: false,
		formSubscribe_email: "",
		formSubscribe_first_name: "",
		formSubscribe_last_name: "",
		formSubscribe_phone_mobile: "",
		subscribeStatus: "",
		subscriptionSuccess: false
	}
}

handleClose = () => {
	this.setState({
		show: false
	})
}

handleShow = () => {
	this.setState({
		formSubscribe_email: this.state.givenemail,
		show: true
	})
}

handleWaitShow = () =>{
	this.setState({
		waitingModalShow: false,
		givenemail: ""})
}
handleModalCheckboxChange = () => {
	if (this.state.acceptConditions === false ) {
	this.setState({ 
		submitOkay: true,
		acceptConditions: true
	 })
	}
	if (this.state.acceptConditions === true ) {
		this.setState({ 
			submitOkay: false,
			acceptConditions: false
		 })
		}
}

handleSubscribe = () => {
	const url = "https://www.dhubrew.com:8084/dhubrew/subscribe";
	const datapacket = {
		email: this.state.formSubscribe_email, 
		first_name: this.state.formSubscribe_first_name,
		last_name: this.state.formSubscribe_last_name,
		phone_mobile: this.state.formSubscribe_phone_mobile
	};
	this.setState({
		show : false,
		waitingModalShow: true
	});
	fetch( url, {
		method: 'POST',
	
		body: JSON.stringify(datapacket),
		headers: {
			'Content-Type': 'application/json', 
			'Accept': 'application/json', 
            "Access-Control-Allow-Headers" : "Content-Type, Accept",
            "Access-Control-Allow-Origin": "https://www.dhubrew.com",
            "Access-Control-Allow-Methods": "GET, POST"
		}
	})
	.then((res) => res.json())
	.then((data)=> {
		this.setState({subscribeStatus: data.resp.Status });
		if (data.resp.Status === "success"){
			this.setState({
				waitingModalShow: false,
				subscriptionSuccess: true
			})
		}
	})
	.catch(err => {const mute = err });
}

render(){
  return (
<Container className="background-image">
	<div>
	<Row className="align-items-center" style={{marginTop: '75px', marginBottom: '50px'}}>
    	<Col xs={0} md={4}></Col>
    	<Col xs={18} md={4}>
		<div style={{display: 'flex', justifyContent: 'center'}}>
			  <img src={DBbyStow_black} style={{width: '300px' }}/>
		</div>
    	</Col>
    	<Col xs={0} md={4}></Col>
  </Row>
  <Row className="align-items-center" style={{marginBottom: '50px'}}>
    <Col xs={0} md={3}>
    </Col>
    <Col xs={18} md={6}>
	<Alert variant='light' style={{opacity: '0.85'}}>
		<strong>	Welcome and thank you for joining Dhu Brew, the home of great Scottish brews. 
					We will let you know where you can get our great beers, what new beers are being brewed, 
					and best of all when and where Dhu Brew events are.
		</strong>
	</Alert>
    </Col>
    <Col xs={0} md={3}>    
    </Col>
  </Row>
  <Row className="align-items-center" style={{marginBottom: '50px'}}>
    <Col xs={0} md={2}>
    </Col>
    <Col xs={18} md={8}>
	{!this.state.subscriptionSuccess &&
	<InputGroup className="mb-3">		  
			  <InputGroup.Text id="basic-addon1" style={{opacity: '0.85' }}><FontAwesomeIcon icon={['fas', 'envelope']} /></InputGroup.Text>
				  <FormControl
					style={{opacity: '0.85'}}
					placeholder="your email"
					aria-label="email"
					aria-describedby="email address"
					onChange={e =>  {
							this.setState({ givenemail: e.target.value });
							if(e.target.value.includes("@")){
								this.setState({initalGetUpdates: false})
							} else { this.setState({initalGetUpdates: true})}
						}}
					type="email"
					value={this.state.givenemail}
				  />
				  <InputGroup.Text id="basic-addon2" style={{opacity: '0.85' }}>
					  <Button 
					  	variant="warning" 
						onClick={this.handleShow}
						disabled={this.state.initalGetUpdates}
						>
						  Get updates
					  </Button>
					  
					  </InputGroup.Text>
			</InputGroup>
	}
	{this.state.subscriptionSuccess && <Alert variant='warning'><FontAwesomeIcon icon={['fas', 'check-square']} />{'  '}Thanks for subscribing we'll be in touch soon!</Alert>}
    </Col>
    <Col xs={0} md={2}>
      
    </Col>
  </Row>

	</div>
	<Modal
	 size="sm"
	 centered
	 id='waitingmodal'
	 show={this.state.waitingModalShow}
	 backdrop="static"
	 keyboard={false}
	 >
    <Modal.Body style={{alignContents: 'center'}}>
		 <p>Adding your details to the Dhu Brew Crew ...</p>
		 <br/>
		<Row >
			<Col></Col>
			<Col className="align-items-center">
				<Spinner
					animation="border" 
					role="status"
					size="lg"
					variant='warning'
					>
  					<span className="visually-hidden">Loading...</span>
				</Spinner>
		</Col>
		<Col></Col>
		</Row>

			{this.state.subscribeStatus === "duplicate found" &&
			<div>
					<Row>
						<Col>
							<p><strong>Some or all of these details have already been used to subscribe before.</strong></p>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button variant='warning' onClick={this.handleWaitShow}>Cancel</Button>
						</Col>
					</Row>
			</div>}
	</Modal.Body>
	</Modal>

	<Modal
	    id='formmodal'
        show={this.state.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Subcribe for updates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Just a few more details so we can stay in touch.
		  <Form>
		  <InputGroup style={{marginTop: '10px' }}>
		  <InputGroup.Text id="modal-pre-email" >email</InputGroup.Text>
		  <FormControl
		    value = {this.state.givenemail}
			type="email"
			id="email"
			name="email"
			readOnly
			onChange={e =>  this.setState({ formSubscribe_email: e.target.value })}
			>
		  </FormControl>
		  </InputGroup>

		  <InputGroup style={{marginTop: '10px' }}>
		  <InputGroup.Text id="modal-pre-first-name">first name </InputGroup.Text>
			<FormControl
				 placeholder="first name"
			 	 id="first_name"
				 type="text"
				 onChange={e =>  this.setState({ formSubscribe_first_name: e.target.value })}>
			</FormControl> 	
		  </InputGroup>
		  <InputGroup style={{marginTop: '10px' }}>
		  <InputGroup.Text id="modal-pre-last-name">last name </InputGroup.Text>
			<FormControl
				 placeholder="last name"
			 	 id="last_name"
				 type="text"
				 onChange={e =>  this.setState({ formSubscribe_last_name: e.target.value })}>
			</FormControl> 	
		  </InputGroup>
		  <InputGroup style={{marginTop: '10px' }}>
		  <InputGroup.Text id="modal-pre-mobile">mobile </InputGroup.Text>
			<FormControl
				 placeholder="+44 ..."
			 	 id="phone-mobile"
				 type="text"
				 onChange={e =>  this.setState({ formSubscribe_phone_mobile: e.target.value })}>
			</FormControl> 	
		  </InputGroup>
		  <InputGroup style={{marginTop: '10px' }}>
		  <Form.Group className="mb-3" controlId="formBasicCheckbox">
		    <Form.Check
			id="accept" 
			type="checkbox" 
			label="I agree to Dhu Brew contacting me about infromation about its products" 
			checked= {this.state.acceptConditions}
			onChange={this.handleModalCheckboxChange}
			/>
  		  </Form.Group>
			</InputGroup>
			<Form.Control
				type='hidden'
				id='assigned_user_id'
				name='assigned_user_id'
				value='862a0176-41dd-98dc-2c1a-616418d46775'
			/>
        </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
		    onClick = {this.handleSubscribe} 
		  	variant="warning" 
			disabled={this.state.submitOkay === false ? true : false}
			>
				Subscribe
			</Button>
        </Modal.Footer>
      </Modal>
</Container>
  );
}}
