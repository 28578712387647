
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
//import styled from 'styled-components';
import {OverlayTrigger, Carousel, Container, Row, Col, InputGroup, Image, Button, Nav, NavDropdown, Form, FormControl, Navbar} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Button } from '@material-ui/core';
import React, { Component, useState} from 'react';
import picData from "./picData.js";



export default class DynamicCarousel extends Component {
    constructor(props) {
      super(props);
      this.state = {
          contentName: this.props.contentName,
          index: 0,
          data: [],
          contentLength: 0,
      }
    }
    componentDidMount(){
      }
  

    render(){
  
    const handleSelect = (selectedIndex, e) => {
         this.setState({index: selectedIndex});
       };
      return (
      <div style={{display: 'flex', justifyContent: 'center', width : "400px"}}>
      <Carousel activeIndex={this.state.index} onSelect={handleSelect} indicators={false}>
        {picData.map((item, key) => 

        <Carousel.Item className="mx"  key={key}>
        <img
          style={{width: "400px", height: "300px"}}
          src={item.imageURL}
          alt={item.imageURL }
        />
        <Carousel.Caption>
          <div>

	          <Button 
			size='sm' 
			variant='outline-warning'>
			<FontAwesomeIcon size='1x' color='#fdc300' icon={['fas', 'thumbs-up']} />
			{'  '}{item.subTitle}
		</Button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
        )}
      </Carousel>
      </div>
    );
  }
}

