import React, {Component, useState} from 'react';
import { Badge, Container, Button,Card, Row, Col, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import redaleshield  from './redaleshield.png';
import ipashield  from './ipashield.png';
import present from './presents_1.png';
import stowbrewery from './stow_brewery_1.png';
import pilsshield from './Pils Shield.png';
import stowtshield from './Stowt Shield.png';
import sportshield from './SportShield.png';
library.add(fas, fab);

export default class Beers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      beers : [],

    }
  }

  async componentDidMount(){
    // Runs after the first render() lifecycle
    document.body.style.background = '#000000';
  }


  render(){


    return (
      <Container className="mx-auto" style={{ backgroundColor: '#000000',marginBottom: '150px', marginTop: '75px'}}>
        <Row className="align-items-center">
          <Col xs={0} md={4}></Col>
          <Col xs={18} md={4}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Image src={stowbrewery} style={{width: '20rem'}} />
            </div>
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>

        <Row  className="align-items-center">
          <Col xs={0} md={4}></Col>
          <Col xs={18} md={4}>
          <div style={{display: 'flex', justifyContent: 'center',marginBottom: '25px'}}>
            <Image src={present} style={{width: '14rem'}} />
            </div>
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>



<Row xs={1} md={3} className="g-4 mx-auto">

    <Col>
      <Card bg='warning' bordr='warning' >
        <Card.Img variant="top" src={ipashield} className='m-auto' style={{width: '14rem'}}/>
        <Card.Body>
          <Card.Title>IPA{' '}<Badge pill bg='secondary'>2023's Top seller</Badge>{' '}</Card.Title>
          <Card.Text>
            A tradional IPA with light Citrus and earthy aroma. The Malt flavour is balanced with a delicate hop bitterness.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col>
      <Card bg='warning' variant='warning' >
        <Card.Img variant="top" src={redaleshield} style={{ width: '14rem' }} className='m-auto'/>
        <Card.Body>
          <Card.Title>Red Ale {' '}<Badge pill bg='danger'>Amazing taste!</Badge>{' '}</Card.Title>
          <Card.Text>
            Deep red copper in colour, with a Caramel malt flavour. The Cascade hops bringing a vibrant Grapefruit and floral aroma.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col>
      <Card bg='warning' variant='warning' >
        <Card.Img variant="top" src={pilsshield} style={{ width: '14rem' }} className='m-auto'/>
        <Card.Body>
          <Card.Title> Pilsner{' '}<Badge pill bg='success'>Available now!</Badge>{' '}</Card.Title>
          <Card.Text>
            A lager by any other name never tasted so good. Ready to challenge the big T for world domination.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

    <Col>
      <Card bg='warning' variant='warning' >
        <Card.Img variant="top" src={stowtshield} style={{ width: '14rem' }} className='m-auto'/>
        <Card.Body>
          <Card.Title> Stowt {' '}<Badge pill bg='dark'>Available now!</Badge></Card.Title>
          <Card.Text>
            A clever reinterpretation of the classic Stout and we don't just mean the name. Get ready to get your iron levels up.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    <Col>
      <Card bg='warning' variant='warning' >
        <Card.Img variant="top" src={sportshield} style={{ width: '14rem' }} className='m-auto'/>
        <Card.Body>
          <Card.Title>Dhu Sport{' '}<Badge pill bg='primary'>In development!</Badge></Card.Title>
          <Card.Text>
            A lighter beer to enjoy after you have pushed yourself to the limit or its your turn to drive back from the dancing. 
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

</Row>
      </Container>
    )}
}
