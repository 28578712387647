import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import './Footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (

      <Navbar bg="#fdc300" variant="light"  fixed="bottom" className="DhuBrewFooter justify-content-center bottom-tab-nav">
                  <Nav className="justify-content-center">
                          <Nav.Link href="/Subscribe">Join Dhu Brew</Nav.Link>
                          <Nav.Link href="/Contact">Contact</Nav.Link>
                          <Nav.Link href="/About">Dhu Brew Crew</Nav.Link>
                          <Nav.Link href="/Privacy">Privacy</Nav.Link>
                  </Nav>
      </Navbar>

  );
};

export default Footer;
