
import React from 'react';

import ReactDOM from 'react-dom';
import App from './app.js';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes'; // where we are going to specify our routes
import ReactGA from 'react-ga';
ReactGA.initialize('UA-217147309-1');


ReactDOM.render(
  <Router>
    <Routes />
  </Router>,
  document.getElementById('root')
);
