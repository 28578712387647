import React, {Component, useState} from 'react';
import { Alert, Row, Col, Form, InputGroup, FormControl, Container, ButtonGroup, Button, Modal,Image } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DBbyStow_black from './DBbyStow_black.png';
import karaoke from './karaoke.jpg';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-217147309-1');
ReactGA.pageview(window.location.pathname + window.location.search);


library.add(fas, fab);

export default class Home extends Component {
constructor(props) {
	super(props)
	this.state = {
		givenemail: "",
		show: false,
		setShow: false
	}
}



sendClickData = () => {
ReactGA.event({
  category: 'User',
  action: 'Home - FOM'
});

}


handleClose = () => {
	this.setState({
		show: false
	})
}

handleShow = () => {
	console.log("show called");
	this.setState({
		show: true
	})
}

render(){



  return (
<Container className="Hbackground-image">
	<div>
	<Row className="align-items-center" style={{marginTop: '75px', marginBottom: '25px'}}>
    	<Col xs={0} md={4}></Col>
    	<Col xs={18} md={4}>
		<div style={{display: 'flex', justifyContent: 'center'}}>
			  <img src={DBbyStow_black} style={{width: '300px' }}/>
		</div>
    	</Col>
    	<Col xs={0} md={4}></Col>
  </Row>

  <Row className="align-items-center" style={{marginBottom: '10px'}}>
    <Col xs={0} md={3}>

    </Col>
    <Col xs={18} md={6}>
	<p className='text-dark' ><strong>
		We few, we happy few are a small group of friends who got together to make beer, great beer. 
		{' '} Our base in the beautiful Scottish Borders helps to ensure that the 'Borderers spirit' runs through each one of our brews. 
		{'  '} We hope you enjoy our beer as much as we do.
	</strong></p>
    </Col>
    <Col xs={0} md={3}>
      
    </Col>
  </Row>




  <Row  style={{marginTop: '10px',marginBottom: '25px'}}>
    <Col xs={1} md={5}>
    </Col>
    <Col xs={16} md={2} className="align-items-center">
		<div style={{display: 'flex', justifyContent: 'center'}}>
		<ButtonGroup style={{opacity: 0.95}}>
			<Button variant='link' className='bg-transparent' href='https://untappd.com/StowBrewery'>
				<FontAwesomeIcon size='2x' color='#111111' icon={['fab', 'untappd']} />
			</Button>
			<Button variant='link' className='bg-transparent' href='https://www.twitter.com/stowbrewery'>
				<FontAwesomeIcon size='2x' color='#111111' icon={['fab', 'twitter']} />
			</Button>
			<Button variant='link' className='bg-transparent' href='https://www.facebook.com/StowBrewery'>
				<FontAwesomeIcon size='2x' color='#111111' icon={['fab', "facebook"]} />
			</Button>
			<Button variant='link' className='bg-transparent' href='https://www.instagram.com/stowbrewery/?hl=en-gb'>
				<FontAwesomeIcon size='2x' color='#111111' icon={['fab', "instagram"]} />
			</Button>
		</ButtonGroup>
		</div>
    </Col>
    <Col xs={1} md={5}>
      
    </Col>
 
  </Row>

<Row style={{marginTop: '0px',marginBottom: '200px'}}>
<Col xs={2} md={3}>
    </Col>
	<Col xs={14} md={6} style={{alignContents: 'center', background: '#fdc300', opacity: '0.8'}}>

		<h4 className="text-center">Karaoke, a Pie and a Pint</h4>
		<h6 className="text-center">Macfie Hall, Heriot</h6>
		<hr />
		<div style={{display: 'flex', justifyContent: 'center'}}>
		<Image className="rounded mx-auto d-block" style={{width: '250px'}} src={karaoke}/>
		</div>
		<hr /> 
		<p style={{fontSize: 'small'}} class="text-center">What could be better than a DhuBrew beer and some great tunes.</p>
                <p style={{fontSize: 'small'}} class="text-center">Come along and enjoy the fun. So many Dhubrew related songs!</p>
                <p style={{fontSize: 'small'}} class="text-left"></p>
                <p style={{fontSize: 'small'}} class="text-left"></p>
                <hr />
		<div style={{display: 'flex', justifyContent: 'center'}}>
			<Button variant='link' className='bg-transparent' href='https://www.facebook.com/share/X8AEQtopsuVma8Q8/'>
				<FontAwesomeIcon size='2x' color='#111111' icon={['fab', "facebook"]} />
			</Button>
               </div>
    </Col>
</Row>

	</div>

</Container>
  );
}}
